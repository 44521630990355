@import "../../variables.scss";

.question {
  flex-direction: column;
  align-items: center;
  display: flex;
  max-width: 685px;
  min-width: 650px;
  margin-bottom: 40px;

  &__number {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
  }

  &__name {
    justify-content: flex-start;
    display: flex;
    margin-bottom: 25px;
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    user-select: none;
  }
}

@media screen and (max-width: $desktopWidth) {
  .question {
    width: 100%;
    min-width: auto
  }
}

@media screen and (max-width: $tabletWidth) {
  .question {
    align-items: flex-start;
    &__number {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}
