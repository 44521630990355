@import "../../variables.scss";

.test-page {
  margin: 25px 0 0px 65px;
  width: calc(100% - 130px);

  &__logo {
    margin: 0 0 30px 0;
  }
  &__questions {
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;

    &-container {
      margin-bottom: 50px;
      width: 100%;
    }
  }

  .codeMirror, textarea {
    width: 100%;
    box-shadow: 0px 0px 8px 3px lightgrey;
    font-size: 18px;
    min-height: 200px;
    min-width: 680px;
  }
}

@media screen and (max-width: 750px) {
  .test-page {
    margin: 30px;
    width: auto;

    .codeMirror {
      width: 100%;
      min-width: auto;
    }

    &__questions {
      display: block;
    }
  }
}

