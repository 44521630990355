@import '../../variables.scss';

.signup-page {
    position: relative;
    z-index: 0;
    overflow-x: hidden;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    padding: 0 0 0 65px;
    background: url('../../assets/images/signup-cover.svg') right top / contain no-repeat $peach;
    font-family: ‘Source Sans Pro’, sans-serif;

    &__logo {
        margin: 20px 0;
    }

    &__links {
      display: flex;
      margin-bottom: 30px;
      
      a {
        margin-right: 15px;
      }
    }

    .assessment-error {
      color: red;
      font-weight: bold;
      padding: 0px 0px 30px 0px;
    }
}

@media screen and (max-width: $desktopToTableWidth) {
  .signup-page {
    padding: 0 20px 0 20px;
  }
}
