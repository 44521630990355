@import '../../variables.scss';

.input {
    &__label {
        font-size: 16px;
        font-weight: 600;

        &-text {
            margin-right: 5px;
        }
    }
    
    &__item {
        margin-top: 8px;
        border: 1px solid transparent;
        border-radius: 5px;
        outline: 1px solid transparent;
        width: 98%;
        height: 60px;
        padding-left: 15px;
        background-color: $light-grey;
        font-size: 16px;
        line-height: 24px;
        color: $text-color;

        &:hover {
            border: 1px solid transparent;
            outline: 1px solid transparent;
        }
    }

    &__error {
        border: 1px solid red;
        
        &:hover {
            border: 1px solid red;
        }
    }
}

@media screen and (max-width: $tabletWidth) {
    .input{   
      &__item {
        width: 95%;
      }
    }
  }