  .radio__input {
        &:checked, &:not(:checked) {
            position: absolute;
    
            & + label {
                position: relative;
                padding-left: 35px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                color: black;
    
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 20px;
                    height: 20px;
                    border: 2px solid #323232;
                    border-radius: 100%;
                    background: #fff;
                }
    
                &:after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background: #323232;
                    position: absolute;
                    top: 6px;
                    left: 6px;
                    border-radius: 100%;
                    transition: all 0.2s ease;
                }
            }
        }
    
        &:not(:checked) + label:after {
            opacity: 0;
            transform: scale(0); 
        }
    
        &:checked + label:after {
            opacity: 1;
            transform: scale(1);
        }
    }



