@import '../../variables.scss';

.btn {
    border: none;
    outline: none;
    width: 216px;
    height: 56px;
    background-color: $blue;
    cursor: pointer;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: $white;

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

@media screen and (max-width: $tabletWidth) {
     .btn {
         width: 100%;
     }
  }