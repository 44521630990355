@import "../../variables.scss";

.registration-form {
  z-index: 50;
  box-shadow: 0px 10px 60px $boxshadow-color;
  max-width: 600px;
  padding: 40px 45px;
  background-color: $white;
  margin-bottom: 30px;

  .react-tel-input {
    width: auto !important;
    margin-right: 5px;
}

  &__apply-btn {
    justify-content: center;
    display: flex;
    margin-top: 35px;
    width: 100%;
  }

  &__header {
    margin: 0px;
    font-size: $h1;
    line-height: 55px;
    font-weight: bold;
  }

  &__subheader {
    font-size: 16px;
    line-height: 25px;
  }

  &__inputs-wrapper {
    margin-top: 55px;
    margin-bottom: 10px;

    &--university {
      margin-bottom: 25px;
    }

    &--multiple {
      display: flex;
      justify-content: space-between;

      .asterics-wrapper {
        display: flex;
      }

      &__section {
        display: flex;

        p {
          margin: 4px;
          font-size: 16px;
          font-weight: 600;
          padding-top: 5px;        
        }
      }

      &__subsection {
        display: flex;
        flex-direction: column;

        input[type="file"] {
          display: none;
        }
        
        .file-upload {
          border: 1px solid #CACACA;
          border-radius: 5px;
          display: inline-block;
          padding: 7px 60px;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }

  &__agreeblock {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 0px;
    font-size: 14px;
    line-height: 24px;
  }

  &__agree {
    font-size: 14px;
  }

  &__asterics {
    color: $red;
    margin-right: 3px;
  }

  &__privacy {
    text-decoration: underline 2px $blue;
    color: $black;
    display: inline;
    cursor: pointer;
  }

  .signup-message {
    padding: 16px;
  }

  .signup-message.error {
    background-color: red;
    width: 100%;
    color: white;
    height: 100%;
  }

  .snackBar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-weight: bold;
    border-radius: 2px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
  }
  .show {
    visibility: visible;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;

    @keyframes fadein {
      from {
        bottom: 0;
        opacity: 0;
      }
      to {
        bottom: 30px;
        opacity: 1;
      }
    }

    @keyframes fadeout {
      from {
        bottom: 30px;
        opacity: 1;
      }
      to {
        bottom: 0;
        opacity: 0;
      }
    }
  }
}

@media screen and (max-width: $tabletWidth) {
  .registration-form {
    padding: 20px 18px;

    &__header {
      font-size: 38px;
      line-height: 46px;
    }

    &__inputs-wrapper {
      margin-top: 30px;
    }

    &__inputs-wrapper--multiple {
      flex-direction: column;
    }
  }
}
