
// Colors
$light-grey: #F4F4F4;
$dark-grey: #898888;
$red: #ff0000;
$black: #000000;
$blue: #0375DB;
$text-color: rgba(0, 0, 0, 0.47);
$white: #ffffff;
$peach: #FFF8EF;
$light-blue: #f0f8ff;
$orange: #FB8C00;
$brown: #a52a2a;
$green: #039684;
$light-orange: #FFB904;
$progress-bar-color: #C4C4C4;
$boxshadow-color: rgba(0, 0, 0, 0.2);

// Font size
$h1: 48px;

// Width
$tabletWidth: 750px;
$desktopWidth: 1345px;
$desktopToTableWidth: 840px;
