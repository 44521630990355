@import '../../variables.scss';
@import 'highlight.js/styles/stackoverflow-light.css';


.possibleAnswer {
    margin-top: 15px;
    padding: 16px 25px;
    font-weight: 600;
    background-color: $light-grey;

    &-body {
        margin-left: 14px;
    }
}