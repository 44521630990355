@import '../../variables.scss';

.result-page {
    padding: 25px 65px;

    &__logo {
        margin-bottom: 30px;
    }

    &__wrapper {
        flex-direction: column;
        align-items: center;
        display: flex;
    }

    &__title {
        margin: 85px 0 25px 0;
        font-size: 36px;
        font-weight: 600;
        text-align: center;
    }

    &__subtitle {
        font-size: 16px;
        line-height: 27px;
        margin-bottom: 30px;
        max-width: 700px;
        text-align: center;
    }

    &__description {
        margin-bottom: 50px;
        max-width: 700px;
        font-size: 24px;
        line-height: 30px;
        font-weight: normal;
        text-align: center;
    }

    button {
        width: 260px;
    }
}

@media screen and (max-width: $tabletWidth) {
    .result-page {
        padding: 25px 25px;
    }
}
