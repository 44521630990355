@import '../../variables.scss';

.timer {
    display: flex;
    align-items: center;

    &__time {
        margin-left: 10px;
        font-size: 24px;
        font-weight: 600;
    }

    &__redtime {
        margin-left: 10px;
        font-size: 24px;
        font-weight: 600;
        color: $red;  
    }

    &__redtimerImg {
        fill: $red;
    }

    &__timerImg {
        fill: $black;
    }
}