@import "../../variables.scss";

.disciplines-page {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 60px 72px;

  &__logo {
    margin: 30px 0 35px 0;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 36px;
    line-height: 34px;
    font-weight: 600;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 30px;
  }

  &__tag {
    border-radius: 8px;
    margin-right: 5px;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    background: #efefef;
  }

  &__items {
    &-title {
      padding: 10px 0 10px 0;
      font-size: 25px;
      font-weight: bold;
    }

    &-subtitle {
      padding: 0 0 25px 0;

      &.green {
        color: $green;
      }
    }

    .disciplines-page__item {
      display: grid;
      grid-template-columns: 24% 24% 24% 24%;
      grid-gap: 20px;
    }
  }
}

.popup-test-rules {
  text-align: center;
}

.popup-window__bar {
  display: inline-block;
  margin: 0 5px;
  height: 15px;
  width: 35px;
  &.answered {
    background-color: $green;
  }
  &.unanswered {
    background-color: $red;
  }

  &.current {
    background-color: $orange;
  }
}

@media screen and (max-width: $desktopWidth) {
  .disciplines-page {
    &__items {
      .disciplines-page__item {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: $tabletWidth) {
  .disciplines-page {
    padding: 15px;

    &__title {
      font-size: 32px;
    }
  }
}
