.not-found-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 15px;

  &__img {
    width: 530px; 
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    > svg {
      transform: scale(2);
    }
  }

  &__title {
   font-size: 24px;
   font-weight: 600;
   font-family: ‘Source Sans Pro’, sans-serif;
   margin: 85px 0 12px 0;
   text-align: center;
  }

  &__description {
    font-size: 16px;
    font-family: ‘Source Sans Pro’, sans-serif;
    margin-bottom: 36px;
    line-height: 25px;
    max-width: 600px;
    text-align: center;
  }

  &__btn {
    width: 167px;
  }
}

@media screen and (max-width: 570px) {
  .not-found-page {
    &__img {
      width: 90%;
      height: auto;
    }
  }
}
