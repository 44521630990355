@import '../../variables.scss';

.checkbox {
    &__container {
        border: none;
        outline: none;
        margin-left: 5px;
        width: 515px;
        font-size: 15px;

        &:hover {
            border: none;
            outline: none
        }
    }

    &__wrapper {
        position: relative;
        display: block;
        margin-top: 5px;
        margin-left: 5px;
        padding-left: 35px;
        cursor: pointer;
    }
    
    &__input {
        position: absolute;
        opacity: 0;
        transform: scale(1.5);
        margin-right: 15px;
        height: 0;
        width: 0;
        cursor: pointer;

        &:checked {
            & ~ .checkbox__checkmark {
                background-color: $white;
            }

            & ~ .checkbox__checkmark:after {
                display: block;
            }
        }
    }

    &__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        outline: 2px solid $black;
        width: 17px;
        height: 17px;
        background-color: $white;

        &:after {
            content: "";
            position: absolute;
            top: 0px;
            left: 5px;
            display: none;
            transform: rotate(45deg);
            border: solid $black;
            border-width: 0 3px 3px 0;
            width: 5px;
            height: 10px;
        }
    }
}

@media screen and (max-width: $desktopToTableWidth) {
    .checkbox {
        &__container {
            width: 100%;
        }
    }
  }