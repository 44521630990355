.spinner {
    display: inline-block;
    width: 80px;
    margin: 0 auto;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .spinner:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid grey;
    border-color: grey transparent grey transparent;
    animation: spinner 1.2s linear infinite;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }