@import "highlight.js/styles/stackoverflow-light.css";
@import '../../variables.scss';

.markdown.questionHeader {
  pre {
    white-space: pre-wrap;
  }

  p {
    margin-bottom: 15px;
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    user-select: none;

    code {
      display: contents;
      font-weight: 500;
      font-size: 19px;
    }
  }

  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    th,
    td {
      border: 1px solid black;
      padding: 3px;
    }
  }
}

.hint {
  font-size: 15px;
  margin: 3px 0 0 0;
  max-width: max-content;
  color: #424242;
}

.markdown {
  p {
    margin: 0px;
  }
}

.markdown.questionAnswerFormatted {
  code {
    padding: 0px;
  }
  pre {
    margin: 0px;
    white-space: pre-wrap;
  }
}
