.testLoad {
    display: flex;
    margin: 80px auto;
    width: 100%;
    align-items: center;
    flex-direction: column;

    &-title {
      margin: 85px 0 25px 0;
      font-size: 36px;
      font-weight: 600;
    }
  }
