@import '../../variables.scss';

.discipline {
    margin-bottom: 20px;
    box-shadow: 0px 8px 30px $boxshadow-color;
    border-radius: 3px;
    padding: 11px 14px 25px 14px;
    background: $white;

    &__title {
        margin: 20px 0 20px 0;
        font-size: 18px;
        line-height: 26px;
        font-weight: 600;
    }

    &__time {
        text-align: right;
        font-weight: 700;
        color: #485353;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
            margin-right: 5px;
        }
    }

    &__description {
        margin: 10px 0;
        font-size: 12px;
    }

    &__tags {
        display: flex;
        margin: 10px 0 15px 0;
    }

    &__score {
        color: #FB8C00;
        font-weight: bold;
        padding-left: 20px;
    }

    &__completion {
      font-size: 15px;
      padding: 13px 0;
      font-weight: bold;
      color: $green
    }

    &__progress {
       border: none;
       outline: none;
       padding: 13px;
       font-size: 17px;
       font-weight: bold;
       text-shadow: $black 1px 1px 1px;
       background-color: $red;
       color: $white
    }

    &__btn {
        border: none;
        outline: none;
        font-weight: bold;
        text-shadow:#a52a2a 1px 1px 1px;
        padding: 13px;
        font-size: 17px;
        background-color: $orange;
        cursor: pointer;
        color: $white;

        &.disabled-btn {
            opacity: 0.6;
        }
    }
}


@media screen and (max-width: $desktopWidth) {
    .discipline {
        min-width: 300px;
    }
}

@media screen and (max-width: $tabletWidth) {
    .discipline {
        width: 100%;
        min-width: auto;
    }
}





