@import '../../variables.scss';

.educationTask-page {
    padding: 0 60px 72px;

    &__logo {
        margin: 30px 0 35px 0;
      }
    &__container {
        margin: 0 auto;
        max-width: 868px;
    }

    h1 {
        font-size: 32px;
        font-weight: 600;
      }

    .allQuestions {
        display: flex;
        cursor: pointer;
        &__text {
            color: #197878;
            margin-left: 10px;
            margin-bottom: 20px;
        }
    }

    .task {
        margin-top: 22px;
        margin-bottom: 20px;
        border: 1px solid #D2D2D2;
        border-radius: 5px 5px 0px 0px;
        &-title {
            background-color: #F5F5F5;
            padding: 20px 29px 22px;
            border-bottom: 1px solid #D2D2D2;
            font-weight: 600;
            font-size: 18px;
        }

        &-body {
            padding: 7px 29px;
        }
    }

    .answer {
        &-title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 14px;
            flex-direction: column-reverse;
            gap: 30px;

            & button {
            width: 70px;
            height: 44px;
            font-size: 14px;
            font-weight: normal;
            }

            &__coding {
                display: flex;
                align-items: center;
                flex-wrap: wrap-reverse;
            
                &-hint {
                    display: flex;
                    margin-left: 10px;
                    align-items: end;

                    &__text {
                        max-width: 600px;
                        margin-left: 5px;
                        font-size: 14px;
                    }
                }

          
            }
        }

      .codeMirror {
        border: 1px solid #D2D2D2;
        font-size: 16px;
      }

      .code-validation {
         margin-top: 20px; 

         .CodeMirror {
            height: max-content;
        }
      }
    }

    .btn-container {
        display: flex;
        justify-content: space-between;
        &__wrapper {
            display: flex;
            justify-content: flex-end;
        }

        .education-btn, .education-btn__prev {
            width: 63px;
            height: 44px;
            font-size: 14px;
            font-weight: normal;

        &__prev {
            width: 95px;
          }
        }

    }

    .test-questions {
        padding-bottom: 20px;
    }
}

@media screen and (max-width: $desktopToTableWidth) {
    .educationTask-page {
        padding: 0 30px 72px;
    }
 }

 @media screen and (max-width: 758px) {
    .answer {
        &-title {
            &__coding {
                gap: 17px;
            }
        }
    }
 }