@import '../../variables.scss';

.popup {
  flex-direction: column;
  display: flex;
  overflow: auto;
  min-width: 646px;
  max-height: 90%;
  padding: 10px 21px 10px 21px;
  background-color: $white;

  &:focus {
    border: none;
    outline: none;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }

  .questionAnswered {
    padding: 17px 6px 17px 0;
    display: flex;
    border-top: 1px solid #E7E7E7;
    justify-content: space-between;

    &:last-child {
      border-bottom: 1px solid #E7E7E7;

    }

    &-container {
      display: flex;
      width: 100%;
      justify-content: space-between;


      .activeOrAnswered {
        color: black;
        font-weight: 600;
        min-width: 110px;
      }

      .untouched {
        color: #A3A3A3;
      }
    }

    &-isAnswered {
      display: flex;
    }

    &-status {
      font-weight: 600;
      margin-left: 50px;
      color: #A3A3A3;
      font-size: 16px;
    }

    &-status_err {
      color: red;
    }
  }

  &-close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    cursor: pointer;
  }

  &__title {
    width: 554px;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 31px;
  }

}

@media screen and (max-width: $desktopToTableWidth) {
  .popup {
    min-width: auto;
    width: 85%;
    &__title {
      width: auto;
    }

    .questionAnswered-status {
      margin-left: 25px;

    }
  }
}
